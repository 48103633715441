import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { TbCsv } from "react-icons/tb";
import { Link } from "react-router-dom";
import { DescEnum_ChamadoSit, SituacaoChamado } from "../../enums/enums";
import CmxInput from "../ui/Input";

import "./style.css";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
      secondary: { main: "#dc004e" },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          footerContainer: {
            backgroundColor: "var(--azul-600)",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            color: "var(--branco)", // cor do texto
            "& .MuiTablePagination-root": {
              color: "var(--branco)", // cor do texto de paginação
            },
            "& .MuiTablePagination-toolbar": {
              color: "var(--branco)", // cor do texto de paginação
            },
            "& .MuiIconButton-root": {
              color: "var(--branco)", // cor dos ícones de paginação
            },
            "& .MuiIconButton-root.Mui-disabled": {
              color: "var(--azul-300)", // cor dos ícones de paginação desabilitados (cinza claro)
            },
            "& .MuiSelect-root": {
              color: "var(--branco)", // cor do seletor de paginação
            },
            "& .MuiSelect-icon": {
              color: "var(--branco)", // cor do ícone do seletor de paginação
            },
            "& .MuiMenuItem-root": {
              color: "var(--branco)", // cor dos itens do menu de paginação
            },
          },
        },
      },
    },
  },
  ptBR
);

const GridChamados = ({ chamados }) => {
  const [filterText, setFilterText] = useState("");
  const [filteredRows, setFilteredRows] = useState(chamados);

  useEffect(() => {
    const filtered = chamados.filter((row) => {
      const chamadoId = String(row.chamado_id || "").toLowerCase();
      const situacao = String(row.situacao || "").toLowerCase();
      const assunto = String(row.assunto || "").toLowerCase();

      return (
        chamadoId.includes(filterText) ||
        situacao.includes(filterText) ||
        assunto.includes(filterText)
      );
    });

    setFilteredRows(filtered);
  }, [chamados, filterText]);

  const handleFilterChange = (event) => {
    setFilterText(event.target.value.toLowerCase());
  };

  const csvData = filteredRows.map((row) => ({
    Chamado: row.chamado_id,
    Situação: DescEnum_ChamadoSit[row.situacao] || "Desconhecido",
    Assunto: row.assunto,
  }));

  const RedCell = ({ value }) => {
    return <div className="custom-red-cell">{value}</div>;
  };

  const YellowCell = ({ value }) => (
    <div className="custom-yellow-cell">{value}</div>
  );

  const columns = [
    {
      field: "id",
      headerName: "Chamado",
      width: 150,
      renderCell: (params) => (
        <Link
          to={`${params.value}`}
          style={{ color: "#1976d2", textDecoration: "underline" }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "situacao",
      headerName: "Situação",
      width: 200,
      getCellClassName: "red-cell",
      valueGetter: (params) => {
        const situacaoDesc = DescEnum_ChamadoSit[params];
        return situacaoDesc || "Desconhecido";
      },
      renderCell: (params) => {
        if (
          params.value ===
            DescEnum_ChamadoSit[SituacaoChamado.Aguardando_Aprovacao] &&
          params.row.aprovado_pelo_portal
        ) {
          return <YellowCell value={params.value} />;
        } else if (
          params.value ===
          DescEnum_ChamadoSit[SituacaoChamado.Aguardando_Aprovacao]
        ) {
          return <RedCell value={params.value} />;
        }
        return params.value;
      },
    },
    { field: "assunto", headerName: "Assunto", width: 350 },
  ];

  return (
    <div style={{ width: "100%", height: "calc(100vh - 180px)" }}>
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={5}
          rowHeight={40}
          headerHeight={40}
          columnHeaderHeight={45}
        />
      </ThemeProvider>
      <div className="custom-input-footer">
        <CmxInput
          type="text"
          placeholder="Localizar..."
          autoFocus={true}
          value={filterText}
          onChange={handleFilterChange}
        />
      </div>

      <div className="custom-csvlink-footer">
        <CSVLink
          data={csvData}
          filename={"chamados.csv"}
          className="btn btn-primary"
          style={{ marginLeft: "10px" }}
        >
          <TbCsv title="Exportar Lista em CSV" size={20} className="csv-icon" />
        </CSVLink>
      </div>
      {/* <CSVDownloader
          type={Type.Button}
          filename={"chamados"}
          bom={true}
          data={csvData}
        >
          Exportar CSV
        </CSVDownloader> */}
      {/* <CmxButton variant="contained" color="primary" onClick={csvDownload}>
          Exportar CSV
        </CmxButton> */}
      {/* <CSVLink
          data={csvData}
          filename={"chamados.csv"}
          className="btn-export"
        >
          Exportar CSV
        </CSVLink> */}
    </div>
  );
};

export default GridChamados;
